/*! CSS Used from: https://book.collegehunkshaulingjunk.com/static/css/main.f00851ab.chunk.css */

#wrapper {
    position: relative;
    /*background:#333;*/
    height: 100%;
}

.profile-main-loader {
    left: 50% !important;
    margin-left: -100px;
    position: fixed !important;
    top: 50% !important;
    margin-top: -100px;
    width: 45px;
    z-index: 9000 !important;
}

.profile-main-loader .loader {
    position: relative;
    margin: 0px auto;
    width: 200px;
    height: 200px;
}

.profile-main-loader .loader:before {
    content: '';
    display: block;
    padding-top: 100%;
}

.circular-loader {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
}

.loader-path {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

@-webkit-keyframes color {
    0% {
        stroke: #EA7200;
    }
    40% {
        stroke: #EA7200;
    }
    66% {
        stroke: #EA7200;
    }
    80%, 90% {
        stroke: #EA7200;
    }
}

@keyframes color {
    0% {
        stroke: #EA7200;
    }
    40% {
        stroke: #EA7200;
    }
    66% {
        stroke: #EA7200;
    }
    80%, 90% {
        stroke: #EA7200;
    }
}


*, :after, :before {
    box-sizing: border-box;
}

form, html {
    border: 0;
}

form {
    margin: 0;
    padding: 0;
}

img {
    border: none;
}

button {
    border: 0;
}

fieldset {
    display: block;
    border: 0;
}

svg[data-use] {
    height: 1em;
    width: 1em;
    fill: currentColor;
}

.third {
    width: 33.3%;
}

.left {
    float: left;
}

.display-none {
    display: none !important;
}

.get-started-form.check-boxes fieldset + .btn-con {
    padding-top: 3em;
}

.get-started-form fieldset li.radio-buttons-img label {
    cursor:  pointer; 
    width: 18em;
    position: relative;
    margin-top: .7em;
}


.get-started-form .back-btn {
    display: block;
    color: #000;
    text-decoration: underline;
    margin-top: 1em;
    font-weight: 500;
}

.get-started-form fieldset.medium-compact {
    max-width: 50em;
    margin: 0 auto;
}

.get-started-form fieldset > ul > li {
    padding-bottom: 1em;
}

.form-box .btn:before {
    border: .2em solid #2c673c;
}

.form-box .btn:after {
    border: .2em solid transparent;
    border-top-color: #fff;
    -webkit-animation: clockwise 1s linear infinite;
    animation: clockwise 1s linear infinite;
}

.form-box .btn:after, .form-box .btn:before {
    content: "";
    position: absolute;
    width: 2em;
    height: 2em;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    -webkit-animation: loading .65s linear infinite;
    animation: loading .65s linear infinite;
    -webkit-transition: .4s ease;
    transition: .4s ease;
    -webkit-transform: translate(-50%, -50%) rotate(0);
    transform: translate(-50%, -50%) rotate(0);
    opacity: 0;
}

@media screen and (max-width: 650px) {
    .get-started-form {
        border-bottom: 4px solid #2c673c;
    }
}

html {
    border: 0;
}

#HeaderZone {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 90;
}

.header {
    opacity: 1;
    -webkit-transition: -webkit-transform .5s ease 0s;
    transition: -webkit-transform .5s ease 0s;
    transition: transform .5s ease 0s;
    transition: transform .5s ease 0s, -webkit-transform .5s ease 0s;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    box-shadow: 0 .1em .2em rgba(0, 0, 0, .22);
    align-items: stretch;
    padding-left: 5%;
}

.header .left-side {
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    width: 20%;
}

.header .left-side .mob-logo {
    position: absolute;
    top: 0;
    -webkit-transform: translateY(6%) scale(.9);
    transform: translateY(6%) scale(.9);
    -webkit-transition: top .4s ease 0s, -webkit-transform .4s ease 0s;
    transition: top .4s ease 0s, -webkit-transform .4s ease 0s;
    transition: transform .4s ease 0s, top .4s ease 0s;
    transition: transform .4s ease 0s, top .4s ease 0s, -webkit-transform .4s ease 0s;
    left: 0;
    width: 22%;
}

.header .left-side .mob-loc-finder {
    display: none;
    color: #db772d;
}

.header .callout {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
}

.header .callout {
    width: 14em;
    align-items: center;
}

.header .callout > a {
    display: block;
}

.header .callout > a.phone-link {
    font-weight: 700;
    font-size: 1.8em;
}

@media screen and (min-width: 1000px) {
    .get-started-branch .header {
        padding-top: 1.1em;
        padding-bottom: 1.5em;
    }
}

@media screen and (max-width: 1000px) {
    .header {
        padding: 0;
    }

    .header .callout {
        display: none;
    }

    .header .left-side .mob-logo {
        position: relative;
        top: 0;
        -webkit-transform: scale(.8);
        transform: scale(.8);
    }

    .header .left-side {
        flex: 0 1 auto;
        max-width: 100%;
        width: 100%;
        background-color: #fff;
        padding: 0 2%;
        justify-content: space-between;
        position: relative;
    }

    .header .left-side:before {
        content: "";
        width: 5.4em;
        position: absolute;
        left: -1px;
        top: 0;
        height: 100%;
        background-color: #db772d;
        -webkit-transition: -webkit-transform .4s ease 0s;
        transition: -webkit-transform .4s ease 0s;
        transition: transform .4s ease 0s;
        transition: transform .4s ease 0s, -webkit-transform .4s ease 0s;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }

    .header .left-side .mob-loc-finder {
        line-height: 1;
        font-size: 2.6em;
        align-items: center;
        justify-content: flex-end;
        display: flex;
    }
}

@media screen and (max-width: 800px) {
    .header {
        position: relative;
    }

    .header .left-side .mob-loc-finder {
        font-size: 2.6em;
    }

    .header .callout {
        display: none;
    }

    .header .left-side {
        width: 100%;
    }

    .header .left-side .mob-logo {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .5em 0;
    }

    .header .left-side .mob-logo img {
        max-height: 5em;
    }
}

@media screen and (max-width: 550px) {
    .header .left-side {
        flex: 0 1 100%;
        justify-content: space-between;
        padding: 0 2% 0 3.5%;
    }

    .header .left-side .mob-loc-finder {
        line-height: 1;
    }
}

@media screen and (max-width: 450px) {
    .header .left-side .mob-loc-finder {
        font-size: 2.75rem;
    }

    .header .left-side .mob-logo img {
        max-height: 4.5em;
    }
}

@media screen and (max-width: 400px) {
    .header .left-side .mob-loc-finder {
        font-size: 3rem;
    }
}

html {
    -webkit-text-size-adjust: 100%;
    font-size: .9375vw;
    overflow-x: hidden;
}

body {
    color: #000;
    margin: 0;
    padding: 0;
    font-size: 100%;
    font-family: Barlow, sans-serif;
    line-height: 1.6;
    overflow: hidden;
    background-color: #fff;
    position: relative;
    -webkit-font-smoothing: antialiased;
}

form *, form :after, form :before {
    box-sizing: border-box;
}

.main {
    width: 100%;
    margin: 0 auto;
    max-width: 86.667em;
    padding-left: 3.333em;
    padding-right: 3.333em;
    position: relative;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
}

img {
    -ms-interpolation-mode: bicubic;
    max-width: 100%;
}

footer, header, main, nav, section {
    display: block;
}

main section {
    padding: 4.222em 0;
}

body > iframe {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}

h1 {
    margin: 0;
    line-height: 1.2;
    font-weight: 400;
}

h1, section header > :first-child:not(a) {
    font-size: 2.5em;
    text-transform: capitalize;
    line-height: .9em;
    color: #2c673c;
    font-family: ivy_league_solidregular, sans-serif;
    font-weight: 400;
}

section header {
    padding-bottom: 2em;
    text-align: center;
}

main section header p {
    margin-top: .5em;
}

main section header > :first-child {
    margin-top: 0;
}

main section header > :last-child {
    margin-bottom: 0;
}

a {
    color: #db772d;
    -webkit-transition: color .4s ease 0s;
    transition: color .4s ease 0s;
}

a, a:hover {
    text-decoration: none;
}

a:hover {
    color: #2c673c;
    outline: none !important;
}

a[href^=tel] {
    cursor: default;
}

.btn {
    display: inline-block;
    text-align: center;
    padding: .7em 3em;
    position: relative;
    cursor: pointer;
    font-weight: 700;
    font-size: 1em;
    text-transform: uppercase;
    -webkit-transition: color .4s ease 0s, background-color .4s ease 0s, border .4s ease 0s;
    transition: color .4s ease 0s, background-color .4s ease 0s, border .4s ease 0s;
    line-height: 1.2;
    font-family: Barlow, sans-serif;
}

.btn.v1 {
    background-color: transparent;
    color: #db772d;
    border: .2em solid #db772d;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)
}

.btn-con {
    display: block;
    margin-top: 1.5em;
}

.btn-con.align-center {
    text-align: center;
}

input, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

fieldset ul {
    justify-content: space-between;
    flex-wrap: wrap;
}

fieldset ul {
    align-items: center;
    display: flex;
}

fieldset > ul > li {
    padding-bottom: 2.5em;
    position: relative;
    width: 100%;
}

fieldset ul .third {
    width: calc(33% - .6em);
}

@media screen and (max-width: 1440px) {
    html {
        font-size: 1.0408vw;
    }

    .main {
        padding-left: 4%;
        padding-right: 4%;
    }

    main section {
        padding: 3.5em 0;
    }

    section header {
        padding-bottom: 2em;
    }

    h1, section header > :first-child:not(a) {
        font-size: 2.5em;
    }
}

@media screen and (max-width: 1200px) {
    html {
        font-size: 1.24875vw;
    }
}

@media screen and (max-width: 1000px) {
    html {
        font-size: 1.7vw;
    }

    main section {
        padding: 3em 0;
    }

    section header {
        padding-bottom: 1.5em;
    }
}

@media screen and (max-width: 800px) {
    html {
        font-size: 2.123vw;
    }
}

@media screen and (max-width: 650px) {
    html {
        font-size: 2.47vw;
    }

    fieldset > ul {
        display: block;
    }

    fieldset > ul > li {
        display: block;
        width: 100%;
    }

    fieldset ul .third {
        width: 100%;
    }

    h1, section header > :first-child:not(a) {
        font-size: 2.3em;
    }
}

@media screen and (max-width: 550px) {
    html {
        font-size: 3.7143vw;
    }

    main section {
        padding: 2.5em 0;
    }
}

.quoteContainer {
    display: flex;
    width: 100%;
    margin: 20px;
}

@media only screen and (min-width: 1201px) {
    .quote {
        position: fixed;
        right: 0;
        top: 100px;
        float: right;
        margin: 20px;
        z-index: 80;
    }

    .quote.no-fixed {
        position: absolute !important;
        right: 0;
        top: 0;
        float: right;
        margin: 2em 0 !important;
    }

    .quote .speech-bubble.left {
        max-width: 20em !important;
    }
}

.quote {
    display: inline-block;
    margin: 2em 20px 0 0;
    text-align: left;
}

.quote p {
    font-weight: 500;
}

.quote blockquote {
    color: tint(#2d4154, 20%);
    margin: 0;
}

.quote .round-avatar {
    border-radius: 50%;
    border: 1px solid #e0e8ed;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .05);
    float: left;
    margin-right: .5em;
    height: 50px;
}

.quote .speech-bubble {
    float: left;
}

.quote .speech-bubble.left {
    border: 1px solid #e0e8ed;
    border-radius: 6px;
    position: relative;
    margin-left: 9px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .04);
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 1ex 1em;
    background: #fdfdfe;
    max-width: calc(100vw - 190px);
}

.quote .speech-bubble.left:after, .quote .speech-bubble.left:before {
    content: "";
    display: block;
    position: absolute;
}

.quote .speech-bubble.left:after {
    top: 16px;
}

.quote .speech-bubble.left:before {
    top: 14px;
}

.quote .speech-bubble.left:after {
    left: -8px;
    border: 8px solid transparent;
    border-right-color: #fdfdfe;
    border-left: none;
}

.quote .speech-bubble.left:before {
    left: -10px;
    border: 10px solid transparent;
    border-right-color: #e0e8ed;
    border-left: none;
}

.quote .speech-bubble p {
    line-height: 1.3em;
    margin: 0;
}

.DayPicker {
    margin: auto;
}

.DayPicker-Month {
    width: 250px;
}

.online-booking-date-picker-desktop {
    display: block !important;
}

.online-booking-date-picker-mobile {
    display: none !important;
}

@media only screen and (max-width: 554px) {
    .online-booking-date-picker-desktop {
        display: none !important;
    }

    .online-booking-date-picker-mobile {
        display: block !important;
    }
}

.online-booking-date {
    text-align: center;
    border: 1px solid #ccc;
}

.online-booking-date-header {
    background-color: #db772d;
    color: #fff;
    padding: 10px;
}

.online-booking-date-text {
    font-size: 1.8em;
}

.online-booking-timeFrame {
    font-size: 1.4em;
    font-weight: 700;
    margin-top: 10px;
}

.online-booking-date-body {
    padding-bottom: 20px;
}

.get-started-form fieldset li.radio-buttons-img label input,textarea {
    width: 0;
    height: 0;
    opacity: 0;
}

.get-started-form fieldset li.radio-buttons-img label input + .radioIndicator {
    width: 16px;
    height: 16px;
    display: inline-block;
    border: 1px solid #dadada;
    border-radius: 5px;
}

.get-started-form fieldset li.radio-buttons-img label input:checked + .radioIndicator {
    background-color: #db772d;
    border: 4px solid transparent;
    background-image: url(https://book.collegehunkshaulingjunk.com/static/media/radio.3bfb8d41.svg);
    background-repeat: no-repeat;
    -webkit-transition: background-color .4s ease 0s;
    transition: background-color .4s ease 0s;
}

.get-started-form header {
    max-width: 40em;
    margin: auto;
}

.get-started-form fieldset li.radio-buttons-img label input[name=selectedTime] {
    float: right;
}

.get-started-form fieldset li.radio-buttons-img .online-booking-date-body label {
    display: inline-block;
    width: 10.3em;
    text-align: left;
    margin: auto auto auto 10px;
}

/*! CSS Used from: https://book.collegehunkshaulingjunk.com/static/css/8.c6231af7.chunk.css */
.DayPicker {
    display: inline-block;
    font-size: 1rem;
}

.DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    padding-bottom: 1em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.DayPicker-Month {
    display: table;
    margin: 1em 1em 0;
    border-spacing: 0;
    border-collapse: collapse;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;
    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    background-position: 50%;
    background-size: 50%;
    background-repeat: no-repeat;
    color: #8b9898;
    cursor: pointer;
}

.DayPicker-NavButton:hover {
    opacity: .8;
}

.DayPicker-NavButton--prev {
    margin-right: 1.5em;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC");
}

.DayPicker-NavButton--next {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg==");
}

.DayPicker-NavButton--interactionDisabled {
    display: none;
}

.DayPicker-Caption {
    display: table-caption;
    margin-bottom: .5em;
    padding: 0 .5em;
    text-align: left;
}

.DayPicker-Caption > div {
    font-weight: 500;
    font-size: 1.15em;
}

.DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
}

.DayPicker-WeekdaysRow {
    display: table-row;
}

.DayPicker-Weekday {
    display: table-cell;
    padding: .5em;
    color: #8b9898;
    text-align: center;
    font-size: .875em;
}

.DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
}

.DayPicker-Body {
    display: table-row-group;
}

.DayPicker-Week {
    display: table-row;
}

.DayPicker-Day {
    border-radius: 50%;
    text-align: center;
}

.DayPicker-Day {
    display: table-cell;
    padding: .5em;
    vertical-align: middle;
    cursor: pointer;
}

.DayPicker-Day--today {
    color: #d0021b;
    font-weight: 700;
}

.DayPicker-Day--outside {
    color: #8b9898;
    cursor: default;
}

.DayPicker-Day--disabled {
    color: #dce0e0;
    cursor: default;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    background-color: #4a90e2;
    color: #f0f8ff;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #51a0fa;
}

.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #f0f8ff;
}

h1, h2 {
    margin: 0;
    line-height: 1.2;
    font-weight: 400;
}

.half {
    width: 50%;
}

.get-started-form fieldset.compact {
    max-width: 34em;
    margin: 0 auto;
}

.get-started-form .input-text select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23db772d%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
    padding: .8em .8em .6em !important;
}

.get-started-form .input-text input[type=text]::-webkit-input-placeholder {
    color: rgba(0, 0, 0, .502);
}

.get-started-form .input-text input[type=text]::-moz-placeholder {
    color: rgba(0, 0, 0, .502);
}

.get-started-form .input-text input[type=text]:-ms-input-placeholder {
    color: rgba(0, 0, 0, .502);
}

.get-started-form .input-text input[type=text]::-ms-input-placeholder {
    color: rgba(0, 0, 0, .502);
}

.get-started-form .input-text input[type=text]::placeholder {
    color: rgba(0, 0, 0, .502);
}

.get-started-form .input-text input[type=email], .get-started-form .input-text input[type=tel], .get-started-form .input-text input[type=text], .get-started-form .input-text select, .get-started-form .input-text textarea {
    padding: .5em .8em .6em;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .502);
}

.get-started-form .input-text input[type=text]:focus,textarea:focus {
    border-color: #db772d;
    box-shadow: inset 0 0 0 1px #db772d;
}

fieldset ul .half {
    width: calc(50% - .6em);
}

fieldset ul .full {
    width: 100%;
    font-size: inherit;
    padding-left: 0;
    padding-right: 0;
}

.input-text {
    position: relative;
}

.input-text input[type=email], .input-text input[type=tel], .input-text input[type=text], .input-text select, .input-text textarea {
    display: block;
    background-color: transparent;
    padding: .25em 0;
    color: #444;
    width: 100%;
    box-shadow: none;
    font-size: 1em;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .502);
    font-weight: 500;
}

.input-text ::-webkit-input-placeholder {
    color: #444;
}

.input-text :-moz-placeholder {
    color: #444;
    opacity: 1 !important;
}

.input-text ::-ms-input-placeholder {
    color: #444;
}

fieldset .validation {
    position: absolute;
    padding: .5em 1em;
    top: 100%;
    z-index: 2;
    font-size: .8em;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    -webkit-transition: all .4s ease 0s;
    transition: all .4s ease 0s;
    white-space: nowrap;
    pointer-events: none;
    background-color: rgba(0, 0, 0, .8);
    color: hsla(0, 0%, 100%, .95);
    right: 0;
}

fieldset .validation:before {
    content: "";
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    border-left: .5em solid transparent;
    border-right: .5em solid transparent;
    border-bottom: .5em solid rgba(0, 0, 0, .8);
    bottom: 100%;
    right: 1em;
}

@media screen and (max-width: 650px) {
    fieldset ul .half {
        width: 100%;
    }
}

.get-started-form fieldset li.radio-buttons-img label input + .smallCheckBoxIndicator {
    width: 14px;
    height: 14px;
    display: inline-block;
    border: 1px solid #dadada;
    border-radius: 5px;
}

.get-started-form fieldset li.radio-buttons-img label input:checked + .smallCheckBoxIndicator {
    background-color: #db772d;
    border: 3px solid transparent;
    background-image: url(assets/checkmark.svg);
    background-repeat: no-repeat;
    -webkit-transition: background-color .4s ease 0s;
    transition: background-color .4s ease 0s;
}

.get-started-form fieldset li.radio-buttons-img label input:disabled + .smallCheckBoxIndicator {
    background-color: #a19b9b;
    -webkit-transition: background-color .4s ease 0s;
    transition: background-color .4s ease 0s;
}

.chhj-tooltip {
    position: absolute;
    display: inline-block;
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

.chhj-tooltip svg {
    height: 1em;
    width: 1em;
}

.chhj-tooltip .chhj-tooltiptext {
    visibility: hidden;
    width: 75%;
    background-color: #FFFFFF; /* $white */
    text-align: center;
    padding: 1em;
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    top: 2em;
    box-shadow: 0 0 15px rgba(0,0,0,0.3);
    line-height: 1.3;
    transition: opacity .4s ease 0s;
    opacity: 0;
    border-top: 3px solid #DB772D; /* $secondary */
}

.chhj-tooltip .chhj-tooltiptext > icon,
.chhj-tooltip .chhj-tooltiptext > svg {
    color: #DB772D; /* $secondary */
    position: absolute;
    text-shadow: 0 0 15px rgba(0,0,0,0.3);
    top: -0.8em;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.2em;
}

.chhj-tooltip > icon,
.chhj-tooltip > svg {
    color: #BABABA;
    transition: color .2s ease 0s;
}

.chhj-tooltip:hover > icon,
.chhj-tooltip:hover > svg {
    color: #000000; /* $dark */
}

.chhj-tooltip .chhj-tooltiptext > h5 {
    font-weight: 600;
    display: block;
    padding-bottom: .3em;
    font-size: 1.3em;
}

.chhj-tooltip:hover .chhj-tooltiptext {
    visibility: visible;
    opacity: 1;
}

.get-started-form fieldset ul li.radio-buttons-img ul {
    justify-content: center;
}

.get-started-form fieldset li.radio-buttons-img > ul li input {
    width: auto !important;
    padding: 0;
    opacity: 0;
    margin-right: 2%;
}

.get-started-form fieldset li.radio-buttons-img label img {
    display: block;
    width: 5em;
    height: 5em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.get-started-form fieldset li.radio-buttons-img label > .bordered {
    height: 0;
    display: block;
    padding-bottom: 100%;
    position: relative;
    z-index: 1;
    background-color: #fff;
}

.get-started-form fieldset li.radio-buttons-img label > .bordered:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 0em solid #bababa;
    -webkit-transition: border .4s ease 0s;
    transition: border .4s ease 0s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.get-started-form fieldset li.radio-buttons-img label input:hover + .bordered:before {
    border: .5em solid #bababa;
}

.get-started-form fieldset li.radio-buttons-img label input:checked + .bordered:before {
    border: .5em solid #db772d;
}

.get-started-form fieldset li.radio-buttons-img label > .bordered strong {
    display: block;
    text-align: center;
    margin-top: 2em;
    position: absolute;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-weight: 600;
    width: 100%;
}

@media screen and (max-width: 450px) {
    .chhj-tooltip {
        display: none;
    }

    .get-started-form fieldset li.radio-buttons-img label > .bordered strong {
        width: 88%;
    }

    .get-started-form fieldset ul li.radio-buttons-img ul li {
        flex: 0 1 50%;
    }
}

h1, h5 {
    margin: 0;
    line-height: 1.2;
    font-weight: 400;
}

h5 {
    font-size: 1em;
}

fieldset li > label {
    display: block;
    position: relative;
    margin-bottom: .25em;
}

fieldset .radio-buttons-img .validation {
    right: 20%;
}

@media screen and (max-width: 1000px) {
    fieldset .radio-buttons-img .validation {
        right: 40%;
    }
}

.get-started-form fieldset li.radio-buttons-img label input + .bordered .checkBoxIndicator {
    width: 2em;
    height: 2em;
    display: inline-block;
    border: 1px solid #dadada;
    border-radius: 5px;
}

.get-started-form fieldset li.radio-buttons-img label input:checked + .bordered .checkBoxIndicator {
    background-color: #db772d;
    border: 5px solid transparent;
    background-image: url(assets/checkmark.svg);
    background-repeat: no-repeat;
    -webkit-transition: background-color .4s ease 0s;
    transition: background-color .4s ease 0s;
}

@media only screen and (max-width: 450px) {
    .hide-on-mobile {
        display: none;
    }
}

.get-started-form fieldset li.radio-buttons-img.narrow label {
    width: 13em;
}

.get-started-form fieldset .narrow label > .bordered {
    padding-bottom: 138%;
}

@media screen and (max-width: 450px) {
    .get-started-form fieldset li.radio-buttons-img.narrow label {
        width: auto;
    }
}

.get-started-form .input-text.address input {
    padding-left: 2em;
}

fieldset ul .two-third {
    width: calc(66% - .6em);
}

@media screen and (max-width: 650px) {
    fieldset ul .third, fieldset ul .two-third {
        width: 100%;
    }
}

@font-face {
    font-family: 'ivy_league_solidregular';
    src: url('assets/ivy_league_solid_regular-webfont.eot');
    src: url('assets/ivy_league_solid_regular-webfont.eot?#iefix') format('embedded-opentype'), url('assets/ivy_league_solid_regular-webfont.woff') format('woff'), url('assets/ivy_league_solid_regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

fieldset .validation {
    position: absolute;
    padding: .5em 1em;
    top: 100%;
    z-index: 2;
    font-size: .8em;
    opacity: 0;
    visibility: hidden;
    transform: translateY(10%);
    transition: all .4s ease 0s;
    white-space: nowrap;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.8);
    color: rgba(255, 255, 255, 0.95);
    right: 0;
}

fieldset .radio-buttons-img .validation {
    right: 20%;
}

fieldset .invalid .validation {
    transform: translate(0);
    visibility: visible;
    opacity: 1;
}

fieldset .invalid.focused .validation, fieldset .invalid:hover .validation {
    opacity: 1;
}

fieldset .validation:before {
    content: '';
    display: block;
    position: absolute;
    height: 0;
    width: 0;
    border-left: solid .5em transparent;
    border-right: solid .5em transparent;
    border-bottom: solid .5em rgba(0, 0, 0, 0.8);
    bottom: 100%;
    right: 1em;
}

body {
    background-color: #fbfbfb;
}

.phone-mask::placeholder {
    color: #718096;
}

.email::placeholder {
    color: #718096;
}

.name-input::placeholder {
    color: #718096 !important;

}

/*new content for calendar overhaull*/
.calendar-compace {
    max-width: 60vw !important;
}

.online-booking-date-picker-desktop {
    width: 300px;
}


.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #EA7200 !important;
}

.AvailColumn-container {
    border: 2px solid #EA7200;
    color: #EA7200;
    font-size: 1.1rem;
    padding: .37rem .75rem;
    margin: 1rem;
    display: inline-block;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.AvailColumn-container-selected {
    transition: all .2s;
    border: 3px solid #EA7200;
    color: #EA7200;
    font-size: 1.1rem;
    font-weight: 500;
    padding: .37rem .75rem;
    margin: 1rem;
    display: inline-block;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.no-availability {
    /*border: 2px solid #ea7200;*/
    color: #8b9898;
    font-size: 1.1rem;
    padding: .37rem .75rem;
    margin: 1rem;
    display: inline-block;
    width: 200px
}

.AvailColumn-container:hover {
    transition: all .2s;
    cursor: pointer;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.AvailColumn-title {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 1rem;
    margin-top: 0;
}

.AvailColumn-scroll-container {
    overflow-y: auto;
    max-height: 380px;
}

.AvailColumn-scroll-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

.AvailColumn-scroll-container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.AvailColumn-container > span {
    white-space: nowrap !important;
}
.AvailWindows-container {
    margin-bottom: 3rem;
}
@media only screen and (max-width: 768px) {
    .AvailColumn-scroll-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        max-height: 1000px;
    }
    .AvailColumn-scroll-container::-webkit-scrollbar {
        display: none;
    }
    .AvailColumn-scroll-container::-webkit-scrollbar-thumb {
        display: none;
    }
    .AvailColumn-container {
        max-width: 400px;
        width: 90%;
    }
    .AvailColumn-container-selected {
        max-width: 400px;
        width: 90%;
    }
}
.always-show-scroll {
    min-height: 102%;
}

.header-custom {
    padding: .5rem;
    /*margin-top: 1rem;*/
    margin-bottom: 1rem;
    background-color: #fff;
    box-shadow: 0em 0em 0.5em rgba(0, 0, 0, .22);
}

.header {
    /*margin-top: 1rem;*/
    max-height: 5rem;
}



.header-logo > img {
    max-height: 4rem;

}
.mob-logo {
    padding-left: 7%;
}
@media only screen and (max-width: 769px) {
    .mob-logo {
        padding-left: 0% !important;
    }
}

@media only screen and (min-width: 769px) {
    .hide-on-desktop {
        display: none !important;
    }
}

.back-icon-container {
    padding-left: 1%;
    font-weight: 500;
    color: #8b9898;
    cursor: pointer;
}

#WizardFooter {
    position: fixed;
    bottom: 0;
    margin-top: 1rem !important;
    z-index: 1000;
    /*margin-bottom: 1rem;*/
    height: 5rem;

    width: 100%;
    background-color: #fff;
    box-shadow: 0em 0em 0.5em rgba(0, 0, 0, .22);
}

#WizardFooter > .bar-container {
    padding-left: 5%;
    width: 100%;
}

#WizardFooter > .chhj-green-button {
    background-color: #026937 !important;
    max-width: 12rem;
    padding: .75rem 1.5rem;
    font-size: 1.1rem;
    font-weight: 400;
    margin-right: 2%;
    border-radius: 12px;
}

.ProgressBar-container {
    width: 40%;
    border-radius: 16px;
}
.progress {
    border-radius: 16px;
}
.progress-bar {
    width: 100%;
    background-color: rgb(44, 103, 60);
    border-radius: 16px;
}

.radio-buttons-img {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding: 0;
}
.label-for-checkbox {
    width: 100% !important;
    padding: 0;
    margin: 0;
}
.label-set{
    cursor: context-menu !important;
    text-align: left !important;
    display: flex;
    flex-direction: row;
}
.label-cursor-pointer{
    cursor: pointer;
}
.label-cursor-context{
    cursor: context-menu !important;
}
.flex-zero{
    flex:0
}
.text-sms{
    flex:1;
    font-size: 12px;
    color: #b6b5b5;
    font-weight: 600;
}
.sms-link{
    cursor: pointer;
    color:#EA7200;
}
@media only screen and (max-width: 769px) {
    .hide-on-tablet {
        display: none;
    }
    #WizardFooter > .chhj-green-button {
        font-size: 1.1rem;
        font-weight: 500;
        margin-right: 5%;
    }
    #WizardFooter> .chhj-green-button > i {
        margin-top: 2px;
    }
    .ProgressBar-container {
        width: 100%;
        margin-right: 2rem;
        margin-left: 1rem;
    }
    #Header_1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        font-size: 3rem;
    }
    .styled-data-table .table-header {
        display: none;
    }
}

/* Coupon_Code styles  */

    .coupon-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin:auto;
        padding: 16px 24px;
        gap: 10px;
        background-color: #69D4A1;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        width: 497px;
        height: 58px;
    }

@media only screen and (max-width: 845px) {
    .coupon-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    padding: 16px 24px;
    grid-gap: 10px;
    gap: 10px;
    background-color: #69D4A1;
    box-shadow: 0px 4px 30px rgb(0 0 0 / 15%);
    border-radius: 4px;
    width: 327px;
    height: 58px;
    }
}

    .coupon-text {
    margin: auto;
    font-family: Noah;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 140%;
    color: rgb(235, 249, 243);
    }

/* End of Coupon_Code styles  */

.bar-container > .step-text {
    text-align: center;
}

.special-message-container > .bold {
    font-weight: 700;
}

.input-text textarea {
    resize: none;
    min-height: 9.375em;
    opacity: 1;
}

.styled-data-table {
    margin: 1em auto 0;
    clear: both;
}

.wide .styled-data-table {
    margin: 1em 0 0 0;
}

.styled-data-table .flex {
    -ms-flex-pack: stretch;
    justify-content: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border: 1px solid #A2A2A2;
    margin-bottom: 1.25em;
    background-color: #FFFFFF;
    padding: 1px;
}

.styled-data-table .flex > * {
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}

.styled-data-table .table-header {
    text-align: center;
    padding: 1em;
    width: 9.833em;
    position: relative;
    background-color: #2C673C;
}

.styled-data-table .table-header h4,.styled-data-table .table-header > *:first-child:not(a) {
    font-weight: 400;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%) rotate(-90deg);
    width: 300%;
    color: #FFFFFF;
}

.styled-data-table table {
    border-spacing: 0;
    border-collapse: collapse;
    width: calc(100% - 9.833em);
}

.styled-data-table table tr:nth-child(even) {
    background-color: #F1F1F1;
}

.styled-data-table table thead tr:nth-child(odd) {
    background-color: #2C673C;
    color: #FFFFFF;
}

.styled-data-table table tr.total {
    background-color: #DB772D;
    color: #FFFFFF;
}

.styled-data-table table tr.total td {
    background-color: transparent !important;
}

.styled-data-table table tr.total td:first-child {
    text-transform: uppercase;
}

.styled-data-table table tr.t-header {
    background-color: #4C4C4C;
    color: #FFFFFF;
    text-transform: uppercase;
}

.styled-data-table table tr.t-header td {
    background-color: transparent !important;
    padding-top: 1em;
    padding-bottom: 1em;
}

.styled-data-table table tr td {
    font-size: 1.111em;
    padding: 0.5em 0.5em 0.5em 1em;
    font-weight: 500;
    line-height: 1.3;
}

.styled-data-table table tr td.center {
    text-align: center;
}

.styled-data-table table tr td span {
    font-size: 0.900em;
}

.styled-data-table > small {
    font-size: 16px;
    line-height: 1.25;
    display: block;
}

.content-area .flex {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: stretch;
    align-items: stretch;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.sq-payment-form {
    color: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    position: inherit !important;
    width: inherit !important;
}

.inline-check {
    width: 20px;
    height: 20px;
}